<app-progress-bar></app-progress-bar>
<div class="flex flex-col h-screen justify-between">
  <div class="outlet-container">
    <router-outlet />
  </div>

  <!--<div class="footer curvas text-center text-gray-500 text-sm mt-6">
      <img src="/assets/images/generic/curvas.png" alt="">
      <div class="links">
        <a href="#" class="text-teal-500">Términos y condiciones</a>,
        <a href="#" class="text-teal-500">Aviso de Privacidad</a> y
        <a href="#" class="text-teal-500">Política de cookies</a>
      </div>
  </div>-->

</div>
