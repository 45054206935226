import { AsyncPipe, CommonModule, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrService } from 'ngx-toastr';
import { ProgressBarComponent } from './public/pages/base/components/progress-bar.component';
import localeDe from '@angular/common/locales/de';
import localeEsCo from '@angular/common/locales/es-CO';
import localeDeExtra from '@angular/common/locales/extra/de';
import localePtBr from '@angular/common/locales/pt';
import localePtBrExtra from '@angular/common/locales/extra/pt';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeEsCo, 'es-CO');
registerLocaleData(localePtBr, 'pt-BR', localePtBrExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@Component({
  selector: 'app-root',
  standalone: true,
  providers: [HttpClient, ToastrService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, TranslateModule, ProgressBarComponent],
})
export class AppComponent {
  title = 'involve-ng-recruiter-2.0';
  constructor(
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    translate.setDefaultLang('es');
  }
}
