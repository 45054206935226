<div class="flex flex-row bg-gray-100">
	<div class="flex flex-col w-1/2 justify-center">
		<img class="self-center mb-5 w-1/5"  src="assets/images/logo-involve.png" alt="">
		<span class="text-xl font-bold self-center mt-3">
			Estamos trabajando para 
		</span>
		<span class="self-center text-lg">
			brindarte un mejor servicio
		</span>
		<span class="self-center mt-2 text-base">
			En breve podrás ver las mejoras en nuestra plataforma.
		</span>
		<span class="self-center mt-2 text-base">
			¡Gracias por tu paciencia!
		</span>
	</div>
	<div class="w-1/2">
		<img src="../../../../assets/images/mantenimiento.png" class="w-full h-auto" alt="">
	</div>
</div>
