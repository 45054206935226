import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ProgressBarService } from '../../../../services/progress-bar.service';

@Component({
    selector: 'app-progress-bar',
    standalone: true,
    imports: [AsyncPipe],
    templateUrl: './progress-bar.component.html',
    styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
    constructor(
        public progressBarService: ProgressBarService
    ) { }
}
